<template>
  <v-card color="white">
    <v-container>
      <v-row align="center">
        <v-col cols="12" lg="8" offset-lg="2">
          <v-container>
            <v-row class="pt-10">
              <v-col><h1 class="body--text display-2">TECNOLOGÍAS</h1></v-col>
            </v-row>
            <v-row>
              <v-col>
                <p class="chivo body--text text-left">
                  Queremos colaborar a construir una economía del token, mediante un ecosistema de tecnologías que favorezcan este desarrollo y se beneficien de operaciones seguras y eficientes. Conocé las herramientas que hacen posible la App descentralizada de TOKENFIT
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <a :href="gitbook_url" target="_blank">
                  <img src="assets/Tecnologias.png" style="width: 100%"/>
                </a>
              </v-col>
            </v-row>
            <v-row align="center" v-if="false">
              <v-col 
                cols="12" md="6" lg="4" 
                v-for="img in imagenes" :key="img"
                :class="[$vuetify.breakpoint.mobile ? 'd-flex justify-center' : '']">
                <a :href="gitbook_url" target="_blank">
                  <v-img :src="img" 
                    max-width="300px" 
                    max-height="150px" 
                    contain />
                </a>
              </v-col>
            </v-row>
          </v-container>
        </v-col>

      </v-row>
    </v-container>
  </v-card>
</template>

<script>
const gitbook_url = `${process.env.VUE_APP_GITBOOK_URL}#tecnologias`;
export default {
  name: 'Tecnologias',
  data() {
    return {
      imagenes: [
        'assets/tech-node.svg',
        'assets/tech-vue.svg',
        'assets/tech-docker.png',
        'assets/tech-solidity.svg',
        'assets/tech-blockchain.png',
        'assets/tech-tether.png',
        'assets/tech-git.png',
        'assets/tech-linux.svg',
        'assets/tech-nginx.svg',
        'assets/tech-gitbook.svg',
        'assets/tech-bnbchain.png',
      ],
      gitbook_url
    }
  }
}
</script>

<style>
.d-flex-content {
  flex-basis: content;
}
</style>